/* 

.dashboard-home {
    padding: 20px;
    background-color: #f9f9f9;
    min-height: 100vh;
    font-family: Arial, sans-serif;
}

.home-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.header h1 {
    font-size: 24px;
    color: #333;
}

.logout-button {
    background-color: #ff4d4f;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.logout-button:hover {
    background-color: #ff7875;
}

.market-data,
.money-flow {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.market-data h2,
.money-flow h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
}

.market-data table {
    width: 100%;
    border-collapse: collapse;
}

.market-data th,
.market-data td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    background-color: #1890ff;
    color: white;
    border: none;
    padding: 10px 15px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
}

.pagination button.active {
    background-color: #40a9ff;
}

.pagination button:hover {
    background-color: #40a9ff;
}

.money-flow select {
    margin-bottom: 20px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ddd;
} */




.dashboard-home {
    padding: 20px;
    background-color: #000033;
    min-height: 100vh;
    font-family: Arial, sans-serif;
}

.home-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.header h1 {
    font-size: 24px;
    color: #333;
}

.logout-button {
    background-color: #ff4d4f;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.logout-button:hover {
    background-color: #ff7875;
}


.market-data,
.money-flow {
    background-color: '#000033';
    color: '#ffffff';
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.market-data {
    /* background-image: url("../../public//assets/images/hero-banner.png"); */
    background-size: cover;
    background-position: center;
    color: #141313;
}

.market-data h2,
.money-flow h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
}

.table-container {
    overflow-x: auto;
    margin-top: 10px;
}

.market-data table {
    width: 100%;
    border-collapse: collapse;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    overflow: hidden;
}

.market-data th,
.market-data td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

.market-data th {
    background-color: #3f51b5;
    color: white;
}

.market-data tr:nth-child(even) {
    background-color: #f2f2f2;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    background-color: #1890ff;
    color: white;
    border: none;
    padding: 10px 15px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
}

.pagination button.active {
    background-color: #40a9ff;
}

.pagination button:hover {
    background-color: #40a9ff;
}

.money-flow select {
    margin-bottom: 20px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ddd;
}
