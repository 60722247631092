.mt-4 {
    margin-top: 2rem;
  }
  
  .table {
    margin-top: 1rem;
  }
  
  h3 {
    margin-top: 1rem;
  }
  
  button {
    margin-right: 10px;
  }
  