.wallet-view {
    padding: 20px;
    background-color: #f0f2f5;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* .logoutButton {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 18px;
    color: #1890ff;
} */

.logoutButton {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    margin-bottom: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 18px;
    color: #1890ff;
}

.walletName {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #1890ff;
}

.walletAddress {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 2px solid #3734f1;
    background-color: #ccc9dd;
}

.walletAddress .copyIcon {
    font-size: 16px;
    cursor: pointer;
    color: #1890ff;
}

.walletAddress .copyIcon:hover {
    color: #40a9ff;
}

.walletAddress div {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.walletAddress .ant-tooltip {
    max-width: 250px;
}

.walletView {
    margin-top: 20px;
}

.sendRow {
    margin-top: 10px;
}

.sendRow p {
    margin-bottom: 0;
}

.nftImage {
    max-width: 100%;
    margin-bottom: 10px;
}

.tooltipText {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tooltipText:hover {
    overflow: visible;
    white-space: normal;
}

@media (max-width: 768px) {
    .wallet-view {
        padding: 10px;
    }
    
    .logoutButton {
        top: 5px;
        right: 5px;
        font-size: 16px;
    }
    
    .walletName {
        font-size: 20px;
    }
}


/* walletView.css */
/* 
.wallet-view {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logoutButton {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    margin-bottom: 10px;
}

.walletName {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.sendRow {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.nftImage {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.ant-tabs-tab-btn {
    font-weight: bold;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1890ff;
} */


/* .wallet-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f0f2f5;
    height: 100vh;
}

.wallet-card {
    width: 100%;
    max-width: 500px;
    background: #fff;
    padding: 24px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    text-align: center;
}

.wallet-card h2 {
    margin-bottom: 8px;
}

.wallet-card p {
    margin-bottom: 16px;
    word-break: break-all;
}

.logout-button {
    width: 100%;
}

.nft-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 16px;
}

.nft-image {
    max-width: 100%;
    max-height: 150px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
} */


/* .wallet-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f0f2f5;
    height: 100vh;
}

.wallet-card {
    width: 100%;
    max-width: 500px;
    background: #fff;
    padding: 24px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    text-align: center;
}

.wallet-card h2 {
    margin-bottom: 8px;
}

.wallet-card p {
    margin-bottom: 16px;
    word-break: break-all;
}

.logout-button {
    width: 100%;
}

.nft-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 16px;
}

.nft-image {
    max-width: 100%;
    max-height: 150px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
} */
