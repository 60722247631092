/* Styles for the content wrapper */
.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  /* Styles for the mnemonic section */
  .mnemonic {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .mnemonic .anticon {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  /* Styles for the seed phrase input */
  .seedPhraseContainer {
    width: 100%;
    margin-bottom: 20px;
  }
  
  /* Styles for the recover wallet button */
  .generateSeedPhraseButton {
    width: 100%;
    margin-bottom: 10px;
  }
  
  /* Styles for the go home link */
  .navigateHomeButton {
    color: #1890ff;
    cursor: pointer;
  }
  
  .navigateHomeButton:hover {
    text-decoration: underline;
  }
  

/* .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f0f2f5;
    height: 100vh;
}

.mnemonic {
    width: 100%;
    max-width: 500px;
    background: #fff;
    padding: 24px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin-bottom: 16px;
}

.mnemonic > div {
    margin-bottom: 16px;
}

.seedPhraseContainer {
    margin-bottom: 16px;
}

.generateSeedPhraseButton {
    margin-bottom: 16px;
    width: 100%;
}

.navigateHomeButton {
    cursor: pointer;
    color: #1890ff;
    text-align: center;
    width: 100%;
} */
