.content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5; /* Example background color */
  }
  
  .mnemonic {
    text-align: center;
    background-color: #fff; /* Example background color */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Example box shadow */
    max-width: 400px;
    width: 100%;
  }
  
  .mnemonic img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .mnemonic h2 {
    color: #333; /* Example text color */
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .generateSeedPhraseButton,
  .openWalletButton {
    width: 100%;
    margin-bottom: 10px;
    padding:25px;
    font-size: 20px;
  }
  
  .navigateHomeButton {
    margin-top: 20px;
    font-size: 14px;
    
  }
  
  .navigateHomeButton a {
    color: #007bff; /* Example link color */
    text-decoration: none;
   
  }
  
  .navigateHomeButton a:hover {
    text-decoration: underline;
    padding:140px;
  }
  