.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f0f2f5;
    height: 100vh;
}

.mnemonic {
    width: 100%;
    max-width: 500px;
    background: #fff;
    padding: 24px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

.mnemonic > div {
    margin-bottom: 16px;
}

.generateSeedPhraseButton {
    margin-bottom: 16px;
    width: 100%;
}

.seedPhraseContainer {
    margin-bottom: 16px;
    padding: 16px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}

.openWalletButton {
    margin-bottom: 16px;
    width: 100%;
}

.navigateHomeButton {
    cursor: pointer;
    color: #1890ff;
    text-align: center;
    width: 100%;
}



/* Extra small devices (phones, 0px and up) */
@media (max-width: 575.98px) {
    .mnemonic {
      padding: 1rem;
    }
  
    .mnemonic .anticon {
      font-size: 1.5rem;
    }
    
    .generateSeedPhraseButton,
    .openWalletButton {
      padding: 0.5rem 1rem;
      font-size: 0.875rem;
    }
  
    .seedPhraseContainer {
      padding: 0.5rem;
    }
  }
  
  /* Small devices (tablets, 576px and up) */
  @media (min-width: 576px) {
    .mnemonic {
      padding: 1.5rem;
    }
  
    .mnemonic .anticon {
      font-size: 1.75rem;
    }
    
    .generateSeedPhraseButton,
    .openWalletButton {
      padding: 0.6rem 1.2rem;
      font-size: 0.925rem;
    }
  
    .seedPhraseContainer {
      padding: 0.75rem;
    }
  }
  
  /* Medium devices (desktops, 768px and up) */
  @media (min-width: 768px) {
    .mnemonic {
      padding: 2rem;
    }
  
    .mnemonic .anticon {
      font-size: 2rem;
    }
  
    .generateSeedPhraseButton,
    .openWalletButton {
      padding: 0.75rem 1.5rem;
      font-size: 1rem;
    }
  
    .seedPhraseContainer {
      padding: 1rem;
    }
  }
  
  /* Large devices (large desktops, 992px and up) */
  @media (min-width: 992px) {
    .mnemonic {
      padding: 2.5rem;
    }
  
    .mnemonic .anticon {
      font-size: 2.25rem;
    }
  
    .generateSeedPhraseButton,
    .openWalletButton {
      padding: 0.75rem 1.5rem;
      font-size: 1.1rem;
    }
  
    .seedPhraseContainer {
      padding: 1.25rem;
    }
  }







/* .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f0f2f5;
    height: 100vh;
}

.mnemonic {
    width: 100%;
    max-width: 500px;
    background: #fff;
    padding: 24px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

.mnemonic > div {
    margin-bottom: 16px;
}

.generateSeedPhraseButton {
    margin-bottom: 16px;
    width: 100%;
}

.seedPhraseContainer {
    margin-bottom: 16px;
    padding: 16px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}

.openWalletButton {
    margin-bottom: 16px;
    width: 100%;
}

.navigateHomeButton {
    cursor: pointer;
    color: #1890ff;
    text-align: center;
    width: 100%;
} */
